import * as React from "react"
import { Helmet } from "react-helmet"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import Seo from "../components/seo"
import PostList from "../components/post-list"
import IntroFrame from "../components/intro-frame"

export const query = graphql`
  query HomeQuery {
    site {
      siteMetadata {
        title
        description
        siteUrl
        author {
          name
        }
        social {
          twitterCreator
          fbAppID
        }
      }
    }
    profilePictureShare: file(name: { eq: "profile-picture-share" }) {
      childImageSharp {
        gatsbyImageData(width: 1200)
      }
    }
  }
`

export default function Home({ data, location }) {
  const { title, description, siteUrl, author, social } = data.site.siteMetadata
  const pageUrl = siteUrl + location.pathname
  const imageShareUrl =
    siteUrl +
    data.profilePictureShare.childImageSharp.gatsbyImageData.images.fallback.src
  const imageShareWidth =
    data.profilePictureShare.childImageSharp.gatsbyImageData.width
  const imageShareHeight =
    data.profilePictureShare.childImageSharp.gatsbyImageData.height

  return (
    <Layout>
      <Seo
        title={`${title}, Machine Learning Engineer and Theoretical Physicist`}
        authorInHeadTitle={false}
        defaultTitle={title}
        description={description}
        siteUrl={siteUrl}
        pageUrl={pageUrl}
        author={author.name}
        imageUrl={imageShareUrl}
        imageWidth={imageShareWidth}
        imageHeight={imageShareHeight}
        imageAlt="Filippo Bovo's profile picture."
        fbAppID={social.fbAppID}
        twitterCreator={social.twitterCreator}
      />
      <Helmet>
        <meta
          name="google-site-verification"
          content="DbcpUdIo8Gwc14-A-AzATDrM3OEew9qZHLUE6BEtiss"
        />
      </Helmet>
      <IntroFrame />
      <PostList />
    </Layout>
  )
}
