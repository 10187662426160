import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import { Github, Linkedin, Twitter, JournalText } from "react-bootstrap-icons"

export default function IntroFrame() {
  const data = useStaticQuery(graphql`
    query authorData {
      site {
        siteMetadata {
          author {
            name
            minibio
          }
        }
      }
    }
  `)

  const { author } = data.site.siteMetadata

  return (
    <div className="container">
      <div className="row d-flex flex-row-reverse gx-lg-2 p-3 mb-3 text-dark rounded bg-white">
        <div class="col-md-8 col-lg-4 mx-auto mb-4 mb-lg-2 d-flex flex-column justify-content-center">
          <StaticImage
            src="../static/profile-picture.png"
            alt="Filippo Bovo's profile picture."
            placeholder="none"
            className="rounded"
          />
        </div>
        <div class="col-lg-8 col-xxl-6 mb-2 mb-lg-2 d-flex flex-column justify-content-center">
          <h1 class="display-5 mb-4">Hi! I'm {author.name}.</h1>
          <p class="lead mb-5">{author.minibio}</p>
          <ul class="list-inline">
            <li class="list-inline-item me-4 position-relative">
              <span title="Github" className="me-2">
                <Github size={22} viewBox="0 0 16 18" />
              </span>
              <a
                href="https://github.com/FilippoBovo"
                target="_blank"
                rel="noopener noreferrer"
                className="stretched-link"
              >
                <span>Github</span>
              </a>
            </li>
            <li class="list-inline-item me-4 position-relative">
              <span title="Linkedin" className="me-2">
                <Linkedin size={22} viewBox="0 0 16 18" />
              </span>
              <a
                href="https://www.linkedin.com/in/filippobovo/"
                target="_blank"
                rel="noopener noreferrer"
                className="stretched-link"
              >
                Linkedin
              </a>
            </li>
            <li class="list-inline-item me-4 position-relative">
              <span title="Twitter" className="me-2">
                <Twitter size={22} viewBox="0 0 16 18" />
              </span>
              <a
                href="https://twitter.com/bovo_filippo"
                target="_blank"
                rel="noopener noreferrer"
                className="stretched-link"
              >
                Twitter
              </a>
            </li>
            <li class="list-inline-item me-4 position-relative">
              <span title="Arxiv" className="me-2">
                <JournalText size={22} viewBox="0 0 16 18" />
              </span>
              <a
                href="http://arxiv.org/a/bovo_f_1"
                target="_blank"
                rel="noopener noreferrer"
                className="stretched-link"
              >
                Arxiv
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  )
}
