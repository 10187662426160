import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import PostCard from "./post-card"

export default function PostList() {
  const data = useStaticQuery(graphql`
    query AllPostsQuery {
      allMdx(
        sort: { fields: [frontmatter___date], order: DESC }
        filter: { frontmatter: { published: { eq: true } } }
      ) {
        nodes {
          frontmatter {
            title
            date(formatString: "MMMM Do, YYYY")
            description
            cover {
              childImageSharp {
                gatsbyImageData(placeholder: BLURRED)
              }
            }
            coverAlt
          }
          slug
        }
      }
    }
  `)

  const posts = data.allMdx.nodes

  return (
    <div className="container">
      <h1 className="mb-4 border-bottom">Posts</h1>
      <div class="album mb-5 bg-white">
        <div class="container">
          <div class="row row-cols-1 row-cols-lg-2 g-4">
            {posts.map(({ frontmatter, slug }) => (
              <PostCard frontmatter={frontmatter} slug={slug} />
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}
