import React from "react"
import { Link } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

function coverImg(cover, coverAlt) {
  if (cover != null) {
    const image = getImage(cover)
    return <GatsbyImage image={image} alt={coverAlt} className="card-img-top" />
  } else {
    return <div className="card-img-top">Missing Image</div>
  }
}

export default function PostCard({ frontmatter, slug }) {
  return (
    <section key={slug} className="col">
      <div class="card h-100">
        {coverImg(frontmatter.cover, frontmatter.coverAlt)}
        <div class="card-body d-flex flex-column">
          <h2 className="fs-3 card-title">{frontmatter.title}</h2>
          <p className="small text-muted fst-italic mb-3">{frontmatter.date}</p>
          <p className="card-text">{frontmatter.description}</p>
          <Link to={`posts/${slug}`} className="stretched-link mt-auto">
            Read the post...
          </Link>
        </div>
      </div>
    </section>
  )
}
